import { styled } from "styled-components";
import imgBg from "assets/svg/bg-banner.svg";


const randomPosition = () =>
  `${Math.random() * 15}vw, ${Math.random() * 10}vh`;

export const Wrapper = styled.div`
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background: url(${imgBg}) no-repeat center center fixed;
  background-attachment: fixed;
  background-position: top;
  padding: 200px 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-attachment: fixed;
  -moz-background-attachment: fixed;
  -o-background-attachment: fixed;
  @media (max-width: 768px) {
    background-attachment: fixed !important;
    background-attachment: fixed;
    background-position: top;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-attachment: fixed;
    -moz-background-attachment: fixed;
    -o-background-attachment: fixed;
  }
`;

export const BannerStyles = {
  Main: styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 840px) {
      flex-direction: column-reverse;
    }
  `,
  Left: styled.div``,
  Right: styled.div`
    > img {
      width: 100%;
      animation: moveEffect 20s infinite;
      @keyframes moveEffect {
        0%,
        100% {
          transform: translate(${randomPosition()}) rotate(0deg);
          opacity: 0.8;
        }
        50% {
          transform: translate(${randomPosition()}) rotate(360deg);
          opacity: 0.3;
        }
      }
      @media (max-width: 840px) {
        max-height: 600px;
      }
    }
  `,
  Title: styled.h1`
    font-size: 40px;
    font-weight: 500;
    margin: 0;
    > span {
      color: #03ffad;
      font-weight: bold;
      font-size: 60px;
    }
  `,
  SubTitle: styled.h3`
    font-size: 18px;
    font-weight: 400;
  `,
  Button: styled.div`
    padding: 50px 0;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    gap: 20px;
    flex-wrap: wrap;
  `,
};

