import { styled } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: fit-content;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const EcoSystemStyles = {
  Main: styled.div`
    padding: 100px 0;
  `,
  Card: styled.div`
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(
      90.35deg,
      rgba(80, 45, 159, 0.132) 100%,
      rgba(8, 2, 28, 0) 100%
    );
    padding: 10px 30px;
    border-radius: 10px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Image: styled.img`
    width: 200px;
    height: 150px;
  `,
  Title: styled.p`
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    display: flex;
    align-items: center;
  `,
};
