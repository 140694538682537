import { styled } from "styled-components";

export const Wrapper = styled.div``;

export const CardNewsStyles = {
  Main: styled.div`
    width: 329px;
  `,
  Head: styled.div`
    height: 218px;
    background: linear-gradient(246.32deg, rgb(5 255 66 / 24%) 2.88%, rgba(217, 217, 217, 0) 98.14%);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Icon: styled.img`
    position: absolute;
    top: 0;
    left: 0;
  `,
  Image: styled.img``,
  Bottom: styled.div`
    text-align: left;
  `,
  Title: styled.p`
    font-size: 14px;
    font-weight: bold;
  `,
  Content: styled.p`
    font-size: 14px;
  `,
};
