import { PAGE } from "constants/page";
import LayoutMain from "layout";
import HomePage from "pages/Home";

import RootErrorBoundaryPage from "pages/RootErrorBoundary";
import { createBrowserRouter } from "react-router-dom";
import Swap from "pages/Swap";
import Faucet from "pages/Faucet";


export const router = createBrowserRouter([
  {
    path: PAGE.dashboard,
    element: <LayoutMain />,
    errorElement: <RootErrorBoundaryPage />,
    children: [
      {
        path: PAGE.home,
        element: <HomePage />,
      },
      {
        path: PAGE.swap,
        element: <Swap />,
      },
      {
        path: PAGE.faucet,
        element: <Faucet />,
      },
    ],
  },
]);
