import { styled } from "styled-components";

export const InputStyles = styled.input`
  width: calc(100% - 30px);
  color: white;
  margin: 5px 0;
  background: linear-gradient(
    115deg,
    rgba(255, 255, 255, 0.08) 33.92%,
    rgba(143, 121, 217, 0.22) 99.86%
  );
  border-radius: 20px;
  border: none;
  &:focus {
    outline: none !important;
    border-color: rgba(255, 255, 255, 0.08);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.08);
  }
  ${(props) => {
    switch (props.size) {
      case "small":
        return `
                padding: 15px 15px;
                `;

      default:
        break;
    }
  }}
  ${(props) => {
    switch (props.type) {
      case "rouded":
        return `
                      border-radius: 50px !important;
                    `;

      default:
        break;
    }
  }}
`;
