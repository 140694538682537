import { NewsStyles, Wrapper } from "./styles";
import { dummyNews } from "./dummy";
import Title from "components/Molecules/Title";
import CardNews from "components/Molecules/CardNews";

export default function News() {
  return (
    <Wrapper>
      <div className="container">
        <NewsStyles.Main>
          <Title
            title={"Featured News"}
            subTitle={
              "Read about our latest events, announcements and updates."
            }
          />
          <NewsStyles.ListNews>
            {dummyNews.map((item, idx) => (
              <CardNews
                image={item.image}
                title={item.title}
                content={item.content}
                key={idx}
              />
            ))}
          </NewsStyles.ListNews>
        </NewsStyles.Main>
      </div>
    </Wrapper>
  );
}
