import eco1 from "assets/img/eco-1.png";
import eco2 from "assets/img/eco-2.png";
import eco3 from "assets/img/eco-3.png";
import eco4 from "assets/img/eco-4.svg";


export const listEcoSystem = [
  {
    image: eco1,
    title: "Swap Tobe",
  },
  {
    image: eco2,
    title: "Blockchain Farm",
  },
  {
    image: eco3,
    title: "Tobe Chain",
  },
  {
    image: eco4,
    title: "Tobe Care",
  },
];
