import { TitleStyles, Wrapper } from "./styles";

export default function Title({ title, subTitle }) {
  return (
    <Wrapper data-aos="fade-up">
      <TitleStyles.MainTitle>{title}</TitleStyles.MainTitle>
      <TitleStyles.SubTitle>{subTitle}</TitleStyles.SubTitle>
    </Wrapper>
  );
}
