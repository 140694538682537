import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import ButtonCustom from "components/Atoms/Button";
import { useState } from "react";
import { SETTING } from "constants/config";

export function ModalChooseCoin({
  open,
  handleClose,
  onChange,
  defaultValue,
  options,
}) {
  const [selected, setSelected] = useState(defaultValue);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="sm"
      PaperProps={{
        style: {
          width: "100%",
          padding: 1,
          backgroundColor: "#1f333fe3",
          color: "white",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        <p
          style={{
            fontWeight: "900",
            fontSize: "24px",
            margin: "0",
            textAlign: "center",
          }}
        >
          Choose coin
        </p>
      </DialogTitle>
      <form onSubmit={() => { }}>
        <DialogContent>
          <Autocomplete
            fullWidth
            options={options}
            getOptionLabel={(option) => option.name}
            sx={{ color: "#03ffad" }}
            defaultValue={defaultValue}
            renderOption={(props, option) => (
              <div {...props}>
                <img
                  height="30"
                  width="30"
                  alt={option?.name}
                  src={SETTING.DOMAIN_API + "/" + option?.image}
                />
                <span style={{ fontWeight: "bold", marginLeft: "10px" }}>
                  {option?.name}
                </span>
              </div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Coin"
                sx={{
                  color: "white",
                  background: "white",
                  borderRadius: "7px",
                }}
                InputLabelProps={{
                  style: { color: "#03ffad" },
                }}
              />
            )}
            onChange={(_, option) => {
              setSelected(option);
            }}
          />
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <ButtonCustom
              onClick={() => {
                onChange(selected);
                handleClose();
              }}
              disabled={!selected}
            >
              SELECT
            </ButtonCustom>
          </div>
        </DialogContent>
      </form>
    </Dialog>
  );
}
