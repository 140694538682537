import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: false,
  isProcessing: false,
  historySwap: null,
};

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    getHistorySwapStart: (state) => {
      state.isProcessing = true;
      state.error = false;
    },
    getHistorySwapSuccess: (state, action) => {
      state.historySwap = action.payload;
      state.isProcessing = false;
      state.error = false;
    },
    getHistorySwapFail: (state, action) => {
      state.error = action.payload;
      state.isProcessing = false;
    },
  },
});

export const {
  getHistorySwapStart,
  getHistorySwapSuccess,
  getHistorySwapFail,
} = historySlice.actions;

export const historySelector = (state) => state.history;

export default historySlice.reducer;
