import Effect from "components/Atoms/Effect";
import Footer from "components/Organisms/Footer";
import Header from "components/Organisms/Header";
import { NotFoundStyles } from "./styles";

export default function RootErrorBoundaryPage() {
  return (
    <>
      <Header />
      <Effect />
      <div className="container">
        <NotFoundStyles.Main>
          <h1>PAGE NOT FOUND</h1>
        </NotFoundStyles.Main>
      </div>

      <Footer />
    </>
  );
}
