import imgNews1 from "assets/img/img-news-1.png";
import imgNews2 from "assets/img/img-news-2.png";
import imgNews3 from "assets/img/img-news-3.png";

export const dummyNews = [
  {
    image: imgNews1,
    title: "You can become a publisher of trading",
    content:
      "The collective wisdom of millions of investors is at your fingertips.",
  },
  {
    image: imgNews2,
    title: "You can become a publisher of trading",
    content:
      "The collective wisdom of millions of investors is at your fingertips.",
  },
  {
    image: imgNews3,
    title: "You can become a publisher of trading",
    content:
      "The collective wisdom of millions of investors is at your fingertips.",
  },
];
