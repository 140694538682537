import Effect from "components/Atoms/Effect";
import Header from "components/Organisms/Header";
import Banner from "pages/Home/components/Banner";
import Coin from "pages/Home/components/Coin";
import EcoSystem from "pages/Home/components/EcoSystem";
import FAQ from "pages/Home/components/FAQ";
import Figures from "pages/Home/components/Figures";
import News from "pages/Home/components/News";
import SwapSpeed from "pages/Home/components/SwapSpeed";

export default function HomePage() {
  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <Effect />
      <Header />
      <Banner />
      <Coin />
      <SwapSpeed />
      <EcoSystem />
      <Figures />
      <News />
      <FAQ />
    </div>
  );
}
