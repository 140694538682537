import { styled } from "styled-components";
import imgBgFooter from "assets/img/bg-footer.png";
export const Wrapper = styled.div`
  padding: 280px 0 50px 0;
  background: url(${imgBgFooter});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  @media (max-width: 768px) {
    padding-top: 200px;
  }
`;

export const FooterStyles = {
  Main: styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 50px;
  `,
  Column: styled.div`
    margin: auto;
  `,
  Title: styled.p`
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #ffffff;
    text-align: left;
  `,
  Logo: styled.img`
    width: 300px;
  `,

  CopyRight: styled.p`
    font-size: 12px;
    line-height: 19px;
    color: #ffffff;
    text-align: center;
  `,
  GroupLink: styled.div`
    display: flex;
    gap: 50px;
  `,
  ListLink: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  `,
  Link: styled.a`
    font-weight: 400;
    font-size: 14px;
    line-height: 40px;
    color: #ffffff;
    text-decoration: none;
  `,
  ListIcon: styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  `,
  Icon: styled.img`
    cursor: pointer;
  `,
};
