/* eslint-disable react-hooks/exhaustive-deps */
import ButtonCustom from "components/Atoms/Button";
import { FormStyles } from "./styles";
import InputBase from "components/Atoms/InputBase";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { masterSelector } from "redux/slice/masterSlice";
import { userSelector } from "redux/slice/userSlice";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import Lottie from "react-lottie";
import loadingLottie from "assets/lottie/faucet.json";
import { checkFaucet, handleFaucet } from "services/faucet";
import { faucetSelector } from "redux/slice/faucetSlice";
import Countdown from "react-countdown";
import dayjs from "dayjs";
import { CircularProgress } from "@mui/material";
import { ModalChooseCoin } from "pages/Swap/components/ModalChooseCoin";
import { SETTING } from "constants/config";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const defaultOption = (data) => {
  return {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
};

const listFaucet = [
  {
    value: 'TOBE',
    name: 'TOBE',
    image: 'image/icons/tbc.png'
  },
  {
    value: 'ETOBE',
    name: 'ETOBE',
    image: 'image/icons/etbc.png'
  }
]

export default function FromSwap() {
  const dispatch = useDispatch();
  const { faucetConfig } = useSelector(masterSelector);
  const { faucetInfo, isProcessing } = useSelector(faucetSelector);
  const { userAuth } = useSelector(userSelector);

  const { address, isConnected } = useWeb3ModalAccount();

  const [openModal, setOpenModal] = useState(false);
  const [coinFaucet, setCoinFaucet] = useState(listFaucet[0]);


  useEffect(() => {
    if (address && userAuth) {
      checkFaucet(dispatch, { symbol: coinFaucet.name });
    }
  }, [address, userAuth, coinFaucet]);

  return (
    <FormStyles.Main>
      <p
        style={{
          fontSize: "35px",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "0",
        }}
      >
        {coinFaucet.name} <span style={{ color: "#03ffad" }}>FAUCET</span>{" "}
      </p>
      <p style={{ textAlign: "center" }}>
        Obtain {coinFaucet.name} coin every {faucetConfig?.faucet_time} hours for seamless and
        confident development.
      </p>
      <FormStyles.Flex>
        <div
          style={{
            flex: 0.7,
          }}
        >
          <div
            style={{
              width: "fit-content",
              textAlign: "center",
              margin: "auto",
            }}
          >
            <Lottie options={defaultOption(loadingLottie)} />
          </div>
        </div>

        <div style={{ flex: 1 }}>
          <ModalChooseCoin
            open={openModal}
            handleClose={() => setOpenModal(false)}
            onChange={(value) =>
              setCoinFaucet(value)
            }
            defaultValue={coinFaucet}
            options={listFaucet}
          />
          <FormStyles.Faucet>
            <FormStyles.Coin>
              Select coin
              <FormStyles.CoinInfo onClick={() => setOpenModal(true)}>
                <img
                  src={SETTING.DOMAIN_API + "/" + coinFaucet?.image}
                  alt={coinFaucet?.name}
                />
                <p>{coinFaucet?.name}</p>
                <ExpandMoreIcon />
              </FormStyles.CoinInfo>
            </FormStyles.Coin>
            <FormStyles.Address>
              Wallet Address
              <InputBase
                disabled
                placeholder={address || "Please connect your wallet"}
                type="text"
                style={{ fontSize: "18px" }}
              />
            </FormStyles.Address>
          </FormStyles.Faucet>


          <div
            style={{
              textAlign: "center",
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {isConnected ? (
              <>
                {!faucetInfo?.status ? (
                  <ButtonCustom>
                    <Countdown
                      renderer={({ hours, minutes, seconds, completed }) => {
                        if (!completed) {
                          return (
                            <span>
                              {String(hours).padStart(2, "0")}:
                              {String(minutes).padStart(2, "0")}:
                              {String(seconds).padStart(2, "0")}
                            </span>
                          );
                        }
                      }}
                      date={dayjs(faucetInfo?.faucet?.created_at).add(
                        faucetConfig?.faucet_time,
                        "hours"
                      )}
                    />
                  </ButtonCustom>
                ) : (
                  <ButtonCustom
                    onClick={() => {
                      handleFaucet(dispatch, { symbol: coinFaucet.name });
                    }}
                  >
                    {isProcessing ? (
                      <CircularProgress color="success" size="1.5rem" />
                    ) : (
                      `FAUCET ${faucetConfig?.faucet_amount} ${coinFaucet.name}`
                    )}
                  </ButtonCustom>
                )}
              </>
            ) : (
              <w3m-button />
            )}
          </div>
        </div>
      </FormStyles.Flex>
    </FormStyles.Main>
  );
}
