/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet } from "react-router-dom";
import { Wrapper } from "./styles";
import Header from "components/Organisms/Header";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch } from "react-redux";
import { setUserInfo } from "redux/slice/userSlice";
import Footer from "components/Organisms/Footer";

export default function LayoutMain() {
  const dispatch = useDispatch();
  async function isMetaMaskConnected() {
    const { ethereum } = window;
    const accounts = await ethereum.request({ method: "eth_accounts" });
    return accounts && accounts.length > 0;
  }

  useEffect(() => {
    isMetaMaskConnected().then((e) => {
      if (!e) {
        localStorage.removeItem("user");
        dispatch(setUserInfo(null));
      }
    });
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      delay: 200,
    });
  }, []);
  return (
    <>
      <Header />
      <Wrapper>
        <Outlet />
      </Wrapper>
      <Footer />
    </>
  );
}
