import { styled } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: fit-content;
`;

export const NewsStyles = {
  Main: styled.div`
    padding: 100px 0;
  `,
  ListNews: styled.div`
    padding-top: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 50px;
  `,
};
