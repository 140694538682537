import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: false,
  isProcessing: false,
  coinInfo: null,
  dataChart: null,
  receiveAddress: null,
  listCoin: null,
  faucetConfig: null,
};

export const masterSlice = createSlice({
  name: "master",
  initialState,
  reducers: {
    // GET COIN INFO
    getCoinInfoStart: (state) => {
      state.isProcessing = true;
      state.error = false;
    },
    getCoinInfoSuccess: (state, action) => {
      state.coinInfo = action.payload;
      state.isProcessing = false;
      state.error = false;
    },
    getCoinInfoFail: (state, action) => {
      state.error = action.payload;
      state.isProcessing = false;
    },
    // GET PRICE TBC
    getDataChartStart: (state) => {
      state.isProcessing = true;
      state.error = false;
    },
    getDataChartSuccess: (state, action) => {
      state.dataChart = action.payload;
      state.isProcessing = false;
      state.error = false;
    },
    getDataChartFail: (state, action) => {
      state.error = action.payload;
      state.isProcessing = false;
    },
    // GET RECEIVE ADDRESS
    getReceiveAddressStart: (state) => {
      state.isProcessing = true;
      state.error = false;
    },
    getReceiveAddressSuccess: (state, action) => {
      state.receiveAddress = action.payload;
      state.isProcessing = false;
      state.error = false;
    },
    getReceiveAddressFail: (state, action) => {
      state.error = action.payload;
      state.isProcessing = false;
    },
    // GET LIST COIN
    getListCoinStart: (state) => {
      state.isProcessing = true;
      state.error = false;
    },
    getListCoinSuccess: (state, action) => {
      state.listCoin = action.payload;
      state.isProcessing = false;
      state.error = false;
    },
    getListCoinFail: (state, action) => {
      state.error = action.payload;
      state.isProcessing = false;
    },
    // FAUCET CONFIG
    getFaucetConfigStart: (state) => {
      state.isProcessing = true;
      state.error = false;
    },
    getFaucetConfigSuccess: (state, action) => {
      state.faucetConfig = action.payload;
      state.isProcessing = false;
      state.error = false;
    },
    getFaucetConfigFail: (state, action) => {
      state.error = action.payload;
      state.isProcessing = false;
    },
  },
});

export const {
  getCoinInfoStart,
  getCoinInfoSuccess,
  getCoinInfoFail,
  getDataChartStart,
  getDataChartSuccess,
  getDataChartFail,
  getReceiveAddressStart,
  getReceiveAddressSuccess,
  getReceiveAddressFail,
  getListCoinStart,
  getListCoinSuccess,
  getListCoinFail,
  getFaucetConfigStart,
  getFaucetConfigSuccess,
  getFaucetConfigFail,
} = masterSlice.actions;

export const masterSelector = (state) => state.master;

export default masterSlice.reducer;
