import { callAPI } from "api";
import { METHOD } from "constants/config";
import { endpointAPI } from "constants/endPointAPI";
import { MESSAGE } from "constants/message";
import { createSwapFail, createSwapStart, createSwapSuccess } from "redux/slice/swapSlice";
import { toastMessage } from "utils/toastMessage";

// HISTORY SWAP
export async function createSwap(dispatch, data) {
  dispatch(createSwapStart());
  const res = await callAPI(endpointAPI.swap.create, METHOD.POST, data);
  if (res.isOk) {
    toastMessage("Swap success!", MESSAGE.SUCCESS);
    return dispatch(createSwapSuccess(res?.data?.data));
  }
  //  ERROR
  toastMessage("Operation failed, please try again!", MESSAGE.ERROR);
  return dispatch(createSwapFail(res.data.message));
}
