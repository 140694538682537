import Title from "components/Molecules/Title";
import { RoadmapStyles, Wrapper } from "./styles";

export default function Figures() {
  return (
    <Wrapper>
      <div className="container">
        <RoadmapStyles.Main>
          <Title
            title={"TBC Figures"}
            subTitle={
              "Instant updates with current numbers and statistical data."
            }
          />
          <RoadmapStyles.Content data-aos="zoom-in">
            <RoadmapStyles.Card>
              <RoadmapStyles.Name>Circulating Supply</RoadmapStyles.Name>
              <RoadmapStyles.Total>16,560,000</RoadmapStyles.Total>
            </RoadmapStyles.Card>
            <RoadmapStyles.Line />
            <RoadmapStyles.Card>
              <RoadmapStyles.Name>Total supply</RoadmapStyles.Name>
              <RoadmapStyles.Total>296,368,789</RoadmapStyles.Total>
            </RoadmapStyles.Card>
            <RoadmapStyles.Line />
            <RoadmapStyles.Card>
              <RoadmapStyles.Name>Market cap</RoadmapStyles.Name>
              <RoadmapStyles.Total>7,253,355</RoadmapStyles.Total>
            </RoadmapStyles.Card>
            <RoadmapStyles.Line />
            <RoadmapStyles.Card>
              <RoadmapStyles.Name>Token Burn</RoadmapStyles.Name>
              <RoadmapStyles.Total>1,169,192,187</RoadmapStyles.Total>
            </RoadmapStyles.Card>
            <RoadmapStyles.Line />
            <RoadmapStyles.Card>
              <RoadmapStyles.Name>Current emissions</RoadmapStyles.Name>
              <RoadmapStyles.Total>1.36/block</RoadmapStyles.Total>
            </RoadmapStyles.Card>
          </RoadmapStyles.Content>
        </RoadmapStyles.Main>
      </div>
    </Wrapper>
  );
}
