import styled from "styled-components";

export const MenuStyles = styled.div`
  display: block;
  box-sizing: content-box;
  cursor: pointer;
  position: relative;

  &.open {
    .top-bar {
      transform-origin: 23px center;
      transform: translateX(-3px) rotate(-45deg);
      transition: all 0.75s cubic-bezier(1, -1.5, 0.5, 2) 0s;
    }

    .btm-bar {
      transform-origin: 26px center;
      transform: translateX(-4px) rotate(45deg);
      transition: all 0.75s cubic-bezier(1, -1.5, 0.5, 2) 0s;
    }

    .mid-bar {
      stroke-dasharray: 0 120 251 251;
      stroke-dashoffset: 1;
    }
  }
  .mid-bar {
    stroke-linecap: round;
    stroke: #fff;
    stroke-dasharray: 0 100 40 400;
    stroke-dashoffset: 101;
    transition: all 750ms ease;
  }

  .top-bar,
  .btm-bar {
    position: absolute;
    width: 25px;
    height: 4px;
    background: white;
    left: 6px;
    top: 8px;
    border-radius: 10px;
    transition: all 500ms cubic-bezier(1, -1, 0.5, 2) 0s;
  }
  .btm-bar {
    top: 25px;
  }
`;
