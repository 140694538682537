import { AllocationStyles, Wrapper } from "./styles";
import imgSwapSpeed from "assets/img/swap-speed.png";
import Title from "components/Molecules/Title";

export default function SwapSpeed() {
  return (
    <Wrapper>
      <div className="container">
        <AllocationStyles.Main>
          <Title title={"Fast SWAP Speed"} subTitle={""} />
          <AllocationStyles.Content>
            <AllocationStyles.Des data-aos="fade-right">
              <b>TobeSwap</b> is a modern technology designed to optimize the
              speed of information exchange and transactions in the system. With
              acceleration of up to <span>99%</span> , <b>TobeSwap</b> allows
              converting and processing data more quickly and efficiently than
              ever before. This brings great benefits to you.
            </AllocationStyles.Des>
            <AllocationStyles.Image src={imgSwapSpeed} data-aos="fade-left" />
          </AllocationStyles.Content>
        </AllocationStyles.Main>
      </div>
    </Wrapper>
  );
}
