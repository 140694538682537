import { styled } from "styled-components";

export const Wrapper = styled.div`
  height: 61px;
  background: rgb(8 2 28 / 62%);
  box-shadow: 0px 4px 4px rgba(54, 53, 53, 0.25);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
`;

export const HeaderStyles = {
  Main: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    z-index: 100;
    position: relative;
    .button-connect {
      @media (max-width: 1070px) {
        display: none;
      }
    }
  `,
  Logo: styled.img`
    cursor: pointer;
    display: block;
    width: 180px;
  `,

  ListMenu: styled.div`
    display: flex;
    gap: 30px;
    align-items: center;
    @media (max-width: 1070px) {
      display: none;
    }
  `,
  Menu: styled.a`
    cursor: pointer;
    font-size: 14px;
    line-height: 36px;
    text-decoration: none;
    color: white;
    transition: all 0.5s ease;
    ${(props) =>
      props.isActive
        ? `
          background: linear-gradient(115.36deg, rgb(45 159 143 / 40%) 33.92%, rgba(8, 2, 28, 0.288) 99.86%);
          border-radius: 32.5px;
          padding: 0px 15px;
      `
        : ""};
  `,
  ButtonConnect: styled.div`
  @media (max-width: 1070px) {
    display: none;
  }

  `,

};

export const Menu = {
  Main: styled.div`
    display: flex;
    gap: 50px;
    color: white;
    font-weight: bold;
    @media (max-width: 1070px) {
      display: none;
    }
  `,
  Mobile: styled.div`
    display: none;
    @media (max-width: 1070px) {
      display: block;
    }
  `,
  Item: styled.a`
    text-decoration: none;
    color: white;
    transition: all 0.5s ease;
    cursor: pointer;
    position: relative;
    display: inline-block;
    font-size: 20px;
    font-weight: 600;
    transition: all 0.4s ease;
    padding: 3px 0;
    ${(props) =>
      props.isActive
        ? `
          background: linear-gradient(115.36deg, rgba(80, 45, 159, 0.4) 33.92%, rgba(8, 2, 28, 0.288) 99.86%);
          border-radius: 32.5px;
          padding: 7px 15px;
      `
        : ""};
    &:hover {
      color: #f44336;
      &::after,
      &::before {
        width: 100%;
        left: 0;
        @media (max-width: 768px) {
          width: 50%;
          left: 25%;
        }
      }
    }

    &::after,
    &::before {
      content: "";
      position: absolute;
      top: calc(100% + 5px);
      width: 0;
      right: 0;
      height: 3px;
    }

    &::before {
      transition: width 0.4s cubic-bezier(0.51, 0.18, 0, 0.88) 0.1s;
      background: #f7c24a;
    }

    &::after {
      transition: width 0.2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
      background: #f44336;
    }
  `,
};

export const HeaderMobile = {
  Main: styled.div`
    display: flex;
    gap: 30px;
    color: white;
    font-weight: bold;
    flex-direction: column;
    position: fixed;
    background: #000000d9;
    width: 100%;
    padding: 40px 0;
    height: 100%;
    z-index: 99;
    top: 0;
    left: 0;
    padding-top: 70px;
    text-align: center;
    @media (min-width: 1025px) {
      display: none;
    }
  `,
  ButtonConnectMB: styled.div`
  margin-left: auto;
  margin-right: auto;

  `
};

export const LineStyles = styled.div`
  width: 100%;
  height: 1px;
  background: #ffffff52;
`;

export const EndHeader = {
  Main: styled.div`
    display: flex;
    justify-content: space-around;
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 30px;
    }
  `,
  Social: styled.div`
    display: flex;
    gap: 20px;
    @media (max-width: 768px) {
      justify-content: center;
    }
  `,
  Reserved: styled.div``,
};

export const Links = styled.a`
  color: #f7864a;
  width: 30px;
  height: 30px;
`;
