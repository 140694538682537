import { styled } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: fit-content;
  background-repeat: no-repeat;
  background-size: cover;
  background: linear-gradient(
    90.35deg,
    rgba(80, 45, 159, 0.132) 0%,
    rgba(8, 2, 28, 0) 100%
  );
`;

export const RoadmapStyles = {
  Main: styled.div`
    padding: 100px 0;
  `,
  Content: styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  `,
  Card: styled.div`
    text-align: center;
    margin: 40px 0;
  `,
  Name: styled.p`
    font-weight: bold;
  `,
  Total: styled.p`
    color: #03ffad;
    font-weight: bold;
    font-size: 24px;
    margin: 0;
  `,
  Line: styled.div`
    width: 1px;
    background: #ffffff2e;
    margin: 30px 0;
  `,
};
