import imgTBC from "assets/img/icon-tbc.png";
import imgUSDT from "assets/img/icon-usdt.png";
import imgBNB from "assets/img/icon-bnb.png";

export const listCoin = [
  {
    icon: imgTBC,
    background:
      "linear-gradient(90deg, #7286be 0%, rgba(114, 134, 190, 0.1) 100%)",
    name: "TBC",
  },
  {
    icon: imgUSDT,
    background:
      "linear-gradient(90deg, rgb(0 149 255 / 40%) 0%, rgba(219, 0, 255, 0.04) 100%)",
    name: "USDT",
  },
  {
    icon: imgBNB,
    background:
      "linear-gradient(90deg, #7286be 0%, rgba(114, 134, 190, 0.1) 100%)",
    name: "BNB",
  },
  {
    icon: imgTBC,
    background:
      "linear-gradient(90deg, #2D941C 0%, rgba(46, 103, 45, 0.10) 100%)",
    name: "TBC",
  },
  {
    icon: imgUSDT,
    background:
      "linear-gradient(89.7deg, rgba(80, 45, 159, 0.4) 0.26%, rgba(59, 0, 255, 0.04) 99.75%)",
    name: "USDT",
  },
  {
    icon: imgBNB,
    background:
      "linear-gradient(90deg, #2D941C 0%, rgba(46, 103, 45, 0.10) 100%)",
    name: "BNB",
  },
];
