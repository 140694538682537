"use client";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";

const projectId = "98ba3392910d4719069959863974187e"; // key

const bep20 = {
  chainId: 56,
  name: "Binance Smart Chain",
  currency: "BNB",
  explorerUrl: "https://bscscan.com",
  rpcUrl: "https://bsc-dataseed.binance.org",
};

const metadata = {
  name: "TBC Swap",
  description: "TBC Swap",
  url: "https://swap.tobescan.com/",
  icons: [
    "https://tobescan.com/static/network_logo.png",
  ],
};

const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: true,
  rpcUrl: "https://bsc-dataseed.binance.org",
  defaultChainId: 56,
});

createWeb3Modal({
  ethersConfig,
  chains: [bep20],
  projectId,
  enableAnalytics: true,
  enableOnramp: true,
  //   themeMode: "light",
  //   themeVariables: {
  //     "--w3m-accent-color": "#F5841F",
  //     "--w3m-accent-fill-color": "#F5841F",
  //     "--w3m-background-color": "#F5841F",
  //   } ,
});

export function Web3Modal({ children }) {
  return children;
}
