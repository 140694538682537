import Lottie from "react-lottie";
import loadingLottie from "assets/lottie/loading.json";
import { Wrapper } from "./styles";

const defaultOption = (data) => {
  return {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
};
export default function FallbackPage() {
  return (
    <Wrapper>
      <Lottie options={defaultOption(loadingLottie)} />
    </Wrapper>

  );
}