import { styled } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: fit-content;
  background: linear-gradient(
    90.35deg,
    rgba(80, 45, 159, 0.132) 0%,
    rgba(8, 2, 28, 0) 100%
  );
`;

export const AllocationStyles = {
  Main: styled.div`
    padding: 100px 0;
  `,
  Content: styled.div`
    padding-top: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 50px;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  `,
  Image: styled.img`
    flex: 1;
    width: 100%;
  `,
  Des: styled.p`
    flex: 1;
    font-size: 18px;
    line-height: 30px;
    padding: 0 50px;
    > span {
      color: #03ffad;
    }
    @media (max-width: 540px) {
      padding: 0 10px;
    }
  `,
};
