import { SETTING } from "./config";

export const endpointAPI = {
  user: {
    info: `${SETTING.DOMAIN_API}/public/user/userinfo`,
  },
  auth: {
    signin: `${SETTING.DOMAIN_API}/api/v1/user/auth/signin`,
  },
  history: {
    swap: `${SETTING.DOMAIN_API}/api/v1/user/swap/history`,
  },
  master: {
    coinInfo: `${SETTING.DOMAIN_API}/api/v1/common/master/price-pair`,
    listCoin: `${SETTING.DOMAIN_API}/api/v1/common/master/list-coin`,
    dataChart: `${SETTING.DOMAIN_API}/api/v1/common/master/data-chart`,
    receiveAddress: `${SETTING.DOMAIN_API}/api/v1/common/master/receive-address`,
    faucetConfig: `${SETTING.DOMAIN_API}/api/v1/common/master/faucet-config`,
  },
  swap: {
    create: `${SETTING.DOMAIN_API}/api/v1/user/swap/create`,
  },
  faucet: {
    check: `${SETTING.DOMAIN_API}/api/v1/user/faucet/check`,
    handle: `${SETTING.DOMAIN_API}/api/v1/user/faucet/handle`,
  },
};
