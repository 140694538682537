import { socials } from "./dummy";
import { FooterStyles, Wrapper } from "./styles";
import logoFooter from "assets/svg/logo-footer.svg";

export default function Footer() {
  const handleRedirect = (href) => {
    window.open(href, "_blank");
  };
  return (
    <Wrapper data-aos="fade-up">
      <div className="container">
        <FooterStyles.Main>
          <FooterStyles.Column>
            <FooterStyles.Logo src={logoFooter} />
            <FooterStyles.CopyRight>
              Copyright 2024 TOBE CHAIN
            </FooterStyles.CopyRight>
          </FooterStyles.Column>
          <FooterStyles.Column>
            <FooterStyles.Title>Products</FooterStyles.Title>

            <FooterStyles.GroupLink>
              <FooterStyles.ListLink>
                <FooterStyles.Link href="https://swaptobe.com/p2p/btc" target="_blank">
                  Swap Tobe
                </FooterStyles.Link>
                <FooterStyles.Link href="https://blockchainfarm.vn/" target="_blank">
                  Blockchain Farm
                </FooterStyles.Link>
                <FooterStyles.Link href="https://tobescan.com/" target="_blank">
                  Tobe Scan
                </FooterStyles.Link>
              </FooterStyles.ListLink>
            </FooterStyles.GroupLink>
          </FooterStyles.Column>
          <FooterStyles.Column>
            <FooterStyles.Title>About us</FooterStyles.Title>

            <FooterStyles.GroupLink>
              <FooterStyles.ListLink>
                <FooterStyles.Link href="" target="_blank">
                  Terms Of Service
                </FooterStyles.Link>
                <FooterStyles.Link href="" target="_blank">
                  Privacy Policy
                </FooterStyles.Link>
              </FooterStyles.ListLink>
            </FooterStyles.GroupLink>
          </FooterStyles.Column>
          <FooterStyles.Column>
            <FooterStyles.Title>Community</FooterStyles.Title>
            <FooterStyles.ListIcon>
              {socials.map((item, index) => (
                <FooterStyles.Icon
                  key={index}
                  src={item.icon}
                  href={item.href}
                  target="_blank"
                  onClick={() => handleRedirect(item.href)}
                />
              ))}
            </FooterStyles.ListIcon>
          </FooterStyles.Column>
        </FooterStyles.Main>
      </div>
    </Wrapper>
  );
}
