export const dummyFAQ = [
  {
    name: "What is NFT?",
    des: "An NFT, or non-fungible token, is a type of digital asset that is not interchangeable with other digital assets of the same type. NFTs are unique, meaning they cannot be replaced by another asset of the same type or value. This makes them valuable and desirable to collectors and investors. NFTs are often used to represent physical objects, like art or collectibles. However, they can also be used to represent digital content, like video games, music, or digital art, and real estate.",
    key: "1",
  },
  {
    name: "What is crypto wallet?",
    des: "A cryptocurrency wallet is a digital wallet that stores your private keys and public addresses, allowing you to securely store, receive, and send digital assets. Cryptocurrency wallets come in many different forms, including software, hardware, and paper wallets.",
    key: "2",
  },
  {
    name: "What is cryptocurrency?",
    des: "Cryptocurrency is a digital asset or designed to work as a medium of exchange that uses a blockchain, a digital ledger, to secure its transactions, to control the creation of additional units, and to verify the transfer of assets. Cryptocurrencies are decentralized, meaning they are not subject to government or financial institution control. Bitcoin, the first and most well-kNOWn cryptocurrency, was created in 2009. Cryptocurrencies are often traded on decentralized exchanges and can also be used to purchase goods and services.",
    key: "3",
  },
  {
    name: "What is TBC token?",
    des: "The TBC Token is a utility token that can be used to exchange goods and services on the TBC Platform. TBC token has the goal of becoming the most widely used cryptocurrency in the world. To achieve this, the team is focusing on building partnerships with businesses and organizations that will help increase the use of TBC token. Additionally, the team is working on increasing the visibility of the currency by listing it on more exchanges and making it easier for people to purchase TBC token.",

    key: "4",
  },
  {
    name: "What is stable coin?",
    des: "A stable coin is a digital asset such (i.e. USDT, USDC) that is pegged to a stable asset, such as gold or the US dollar. This makes stable coins less volatile than other cryptocurrencies, which makes them attractive for use in transactions and as a store of value",
    key: "5",
  },
  {
    name: "Where can I buy TBC token?",
    des: "Simple.  Click here to join.  It’s 100% free plus you will get 50 TBC just for sign up.  ",
    key: "6",
  },
];
