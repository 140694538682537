import { MESSAGE } from "constants/message";
import { setUserInfo } from "redux/slice/userSlice";
import { toastMessage } from "./toastMessage";
import { handleSignin } from "services/auth";

export const connectWallet = async (dispatch, userInfo, searchParams) => {
  if (userInfo) {
    localStorage.removeItem("user");
    dispatch(setUserInfo(null));
    toastMessage("Disconnected successfully!", MESSAGE.SUCCESS);
    return;
  }
  try {
    if (window.ethereum) {
      const account = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      if (account && account.length) {
        const ref = searchParams.get("ref");
        handleSignin(dispatch, { wallet: account[0], ref: ref ? ref : "" });
        dispatch(setUserInfo({ address: account[0] }));
        localStorage.setItem("user", JSON.stringify({ address: account[0] }));
      }
    }
  } catch (error) {
    console.log(error);
    toastMessage("Metamask is open! Please confirm!", MESSAGE.ERROR);
  }
};
