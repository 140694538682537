import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: false,
  isProcessing: false,
};

export const swapSlice = createSlice({
  name: "swap",
  initialState,
  reducers: {
    createSwapStart: (state) => {
      state.isProcessing = true;
      state.error = false;
    },
    createSwapSuccess: (state, action) => {
      state.isProcessing = false;
      state.error = false;
    },
    createSwapFail: (state, action) => {
      state.error = action.payload;
      state.isProcessing = false;
    },
  },
});

export const {
  createSwapStart,
  createSwapSuccess,
  createSwapFail,
} = swapSlice.actions;

export const swapSelector = (state) => state.swap;

export default swapSlice.reducer;
