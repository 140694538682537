import { styled } from "styled-components";

export const FormStyles = {
  Main: styled.div`
    padding: 20px;
    background: rgba(217, 217, 217, 0.2);
    border-radius: 20px;
    flex: 40%;
    > button {
      padding: 10px 40px;
      display: flex;
      margin: 10px auto;
    }
  `,
  Pair: styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
  `,
  Head: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  IconSwap: styled.img`
    display: flex;
    margin: auto;
    padding: 5px 0;
    cursor: pointer;
  `,
  Title: styled.p`
    font-weight: bold;
    font-size: 28px;
    margin: 0;
  `,
  SubTitle: styled.p`
    color: #949494;
  `,
  CoinInfo: styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    background: linear-gradient(
      115deg,
      rgba(255, 255, 255, 0.08) 33.92%,
      rgba(143, 121, 217, 0.22) 99.86%
    );
    padding: 0 10px;
    border-radius: 20px;
    margin-top: 5px;
    cursor: pointer;
    > img {
      width: 30px;
      height: 30px;
    }
    > p {
      font-weight: bold;
    }
  `,
  Flex: styled.div`
    display: flex;
    padding: 30px 0;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  `,
  Faucet: styled.div`
    display: flex;
    gap: 10px;
  `,
  Coin: styled.div``,
  Address: styled.div`
    width: 100%;
  `
};
