import { AboutUsStyles, Wrapper } from "./styles";
import CountUp from "react-countup";
import Marquee from "react-fast-marquee";
import { listCoin } from "./dummy";

export default function Coin() {
  return (
    <Wrapper>
      <div className="container">
        <AboutUsStyles.Title>
          Shaping the Future of Decentralized Trading: <br /> TobeSwap’s
          Unstoppable Expansion
        </AboutUsStyles.Title>
        <AboutUsStyles.ListCard>
          <AboutUsStyles.Card>
            <AboutUsStyles.Name>Total Users:</AboutUsStyles.Name>
            <AboutUsStyles.Total>
              <CountUp end={1245490} duration={5} />
            </AboutUsStyles.Total>
            <AboutUsStyles.Sub>in the last 30 days</AboutUsStyles.Sub>
          </AboutUsStyles.Card>
          <AboutUsStyles.Card>
            <AboutUsStyles.Name>Total Trades:</AboutUsStyles.Name>
            <AboutUsStyles.Total>
              <CountUp end={18386885} duration={5} />
            </AboutUsStyles.Total>
            <AboutUsStyles.Sub>in the last 30 days</AboutUsStyles.Sub>
          </AboutUsStyles.Card>
          <AboutUsStyles.Card>
            <AboutUsStyles.Name>Total Value Locked:</AboutUsStyles.Name>
            <AboutUsStyles.Total>
              $<CountUp end={1595877241} duration={5} />
            </AboutUsStyles.Total>
            <AboutUsStyles.Sub>in the last 30 days</AboutUsStyles.Sub>
          </AboutUsStyles.Card>
        </AboutUsStyles.ListCard>
        <Marquee>
          {listCoin.map((item, idx) => (
            <AboutUsStyles.Coin style={{ background: item.background }}>
              <img src={item.icon} alt={item.name} key={idx} />
              <AboutUsStyles.CoinName>{item.name}</AboutUsStyles.CoinName>
            </AboutUsStyles.Coin>
          ))}
        </Marquee>
      </div>
    </Wrapper>
  );
}
