import { MenuStyles } from "./styles";

export default function ButtonOpenClose({ open, setOpen }) {
  return (
    <>
      <MenuStyles
        onClick={() => setOpen(!open)}
        className={open ? "open" : "close"}
      >
        <div className="top-bar"></div>
        <svg
          width="36"
          height="36"
          viewBox="0 0 84 84"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="mid-bar"
            fill="none"
            strokeWidth="4px"
            strokeMiterlimit="10"
            d="M22,42h40
                c5,0, 20, -10, 14.641,-20
                A40,40, 0 0 0 42 2 
                A40,40, 0 0 0 2 42	
                A40 40 0 0 0 42 82
                A40 40 0 0 0 82 42
                A40 40 0 0 0 42 2
                A40 40 0 0 0 2 42			
                        "
          />
        </svg>
        <div className="btm-bar"></div>
      </MenuStyles>
    </>
  );
}
