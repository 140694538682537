import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: false,
  isProcessing: false,
  isProcessingVote: false,
  userAuth: null,
  userInfo: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // SIGNIN
    signInStart: (state) => {
      state.isProcessing = true;
      state.error = false;
    },
    signInSuccess: (state, action) => {
      state.userAuth = action.payload;
      state.isProcessing = false;
      state.error = false;
    },
    signInFail: (state, action) => {
      state.error = action.payload;
      state.isProcessing = false;
    },
    // SET USER INFO
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
  },
});

export const { signInStart, signInSuccess, signInFail, setUserInfo } =
  userSlice.actions;

export const userSelector = (state) => state.user;

export default userSlice.reducer;
