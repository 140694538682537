/* eslint-disable react-hooks/exhaustive-deps */
import Effect from "components/Atoms/Effect";
import { Wrapper } from "layout/styles";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCoinInfo, getListCoin, getReceiveAddress } from "services/master";
import Chart from "./components/Chart";
import FromSwap from "./components/Form";
import { SwapStyles } from "./styles";

export default function Swap() {
  const dispatch = useDispatch();
  useEffect(() => {
    getListCoin(dispatch);
    getCoinInfo(dispatch, { pair: "TBC_USDT" });
    getReceiveAddress(dispatch);
  }, []);
  return (
    <Wrapper>
      <Effect />
      <div className="container">
        {/* {userInfo && <ReferalLink />} */}
        <SwapStyles.Main>
          <Chart />
          <FromSwap />
        </SwapStyles.Main>
      </div>
    </Wrapper>
  );
}
