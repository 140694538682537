import ButtonCustom from "components/Atoms/Button";
import { BannerStyles, Wrapper } from "./styles";
import giftBanner from "assets/img/banner.svg";
import ParticlesComponent from "components/Atoms/Particles";
import { useNavigate } from "react-router-dom";
import { PAGE } from "constants/page";


export default function Banner() {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <div className="container">
        <ParticlesComponent />
        <BannerStyles.Main>
          <BannerStyles.Left>
            <BannerStyles.Title>Everyone's</BannerStyles.Title>
            <BannerStyles.Title>
              <span>Favorite</span> DEX
            </BannerStyles.Title>
            <BannerStyles.SubTitle>
              Trade, earn, and own crypto on the all-in-one multichain DEX
            </BannerStyles.SubTitle>
            <BannerStyles.Button>
              <ButtonCustom onClick={() => navigate(PAGE.swap)} type="white">
                Swap TBC
              </ButtonCustom>
            </BannerStyles.Button>
          </BannerStyles.Left>
          <BannerStyles.Right>
            <img src={giftBanner} alt="banner" />
          </BannerStyles.Right>
        </BannerStyles.Main>
      </div>
    </Wrapper>
  );
}
