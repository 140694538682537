export const TIME_OUT = 30000;

export const METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

export const SETTING = {
  // OFFICIAL
  DOMAIN_API: "https://api.tobeswap.tocuna.com",
  DOMAIN_FE: "https://tobeswap.tocuna.com",

  // LOCAL
  // DOMAIN_API: "http://localhost:3211",
  // DOMAIN_FE: "https://nowswap.toearnnow.com",

  // TEST
  // DOMAIN_API: "https://nowswap.tocuna.com",
  // DOMAIN_FE: "https://nowswap.tocuna.com",

  CONTRACT_TBC: "0xaA4cE8d0AdfdA33DCef335c595c7d042b5CADCf2",
  CONTRACT_USDT: "0x55d398326f99059fF775485246999027B3197955",
};
