import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: false,
  isProcessing: false,
  isProcessingVote: false,
  faucetInfo: null,
};

export const faucetSlice = createSlice({
  name: "faucet",
  initialState,
  reducers: {
    // CHECK FAUCET
    checkFaucetStart: (state) => {
      state.isProcessing = true;
      state.error = false;
    },
    checkFaucetSuccess: (state, action) => {
      state.faucetInfo = action.payload;
      state.isProcessing = false;
      state.error = false;
    },
    checkFaucetFail: (state, action) => {
      state.error = action.payload;
      state.isProcessing = false;
    },
    // HANDLE FAUCET
    handleFaucetStart: (state) => {
      state.isProcessing = true;
      state.error = false;
    },
    handleFaucetSuccess: (state, action) => {
      state.isProcessing = false;
      state.error = false;
    },
    handleFaucetFail: (state, action) => {
      state.error = action.payload;
      state.isProcessing = false;
    },
  },
});

export const {
  checkFaucetStart,
  checkFaucetSuccess,
  checkFaucetFail,
  handleFaucetStart,
  handleFaucetSuccess,
  handleFaucetFail,
} = faucetSlice.actions;

export const faucetSelector = (state) => state.faucet;

export default faucetSlice.reducer;
