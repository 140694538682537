/* eslint-disable react-hooks/exhaustive-deps */
import Effect from "components/Atoms/Effect";
import { Wrapper } from "layout/styles";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import FromSwap from "./components/Form";
import { SwapStyles } from "./styles";
import { getFaucetConfig } from "services/master";

export default function Faucet() {
  const dispatch = useDispatch();
  useEffect(() => {
    getFaucetConfig(dispatch);
  }, []);
  return (
    <Wrapper>
      <Effect />
      <div className="container">
        <SwapStyles.Main>
          <FromSwap />
        </SwapStyles.Main>
      </div>
    </Wrapper>
  );
}
