import { configureStore } from "@reduxjs/toolkit";
import userReducer from "redux/slice/userSlice";
import historyReducer from "redux/slice/historySlice";
import masterReducer from "redux/slice/masterSlice";
import swapReducer from "redux/slice/swapSlice";
import faucetReducer from "redux/slice/faucetSlice";

export function makeStore() {
  return configureStore({
    reducer: {
      user: userReducer,
      history: historyReducer,
      master: masterReducer,
      swap: swapReducer,
      faucet: faucetReducer,
    },
  });
}

const store = makeStore();

export default store;
