import { CardNewsStyles, Wrapper } from "./styles";
import iconCardNews from "assets/svg/icon-card.svg";

export default function CardNews({ image, title, content }) {
  return (
    <Wrapper data-aos="zoom-in">
      <CardNewsStyles.Main>
        <CardNewsStyles.Head>
          <CardNewsStyles.Icon src={iconCardNews} />
          <CardNewsStyles.Image src={image} />
        </CardNewsStyles.Head>
        <CardNewsStyles.Bottom>
          <CardNewsStyles.Title>{title}</CardNewsStyles.Title>
          <CardNewsStyles.Content>{content}</CardNewsStyles.Content>
        </CardNewsStyles.Bottom>
      </CardNewsStyles.Main>
    </Wrapper>
  );
}
