import { styled } from "styled-components";

export const FormStyles = {
  Main: styled.div`
    padding: 20px;
    background: rgba(217, 217, 217, 0.2);
    border-radius: 20px;
    flex: 40%;
    > button {
      padding: 10px 40px;
      display: flex;
      margin: 10px auto;
    }
  `,
  Pair: styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
  `,
  Head: styled.div`
    display: flex;
    justify-content: space-between;
    >img {
      width: 20%;
    }
  `,
  IconSwap: styled.img`
    display: flex;
    margin: auto;
    padding: 5px 0;
  `,
  Title: styled.p`
    font-weight: bold;
    font-size: 28px;
    margin: 0;
  `,
  SubTitle: styled.p`
    color: #949494;
  `,
  CoinInfo: styled.div`
    display: flex;
    gap: 7px;
    align-items: center;
    margin-bottom: -10px;
    margin-top: -10px;
    cursor: pointer;
    > img {
      width: 30px;
      height: 30px;
    }
    > p {
      font-weight: bold;
    }
  `,
};
