import { styled } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: fit-content;
`;

export const AboutUsStyles = {
  Coin: styled.div`
    margin: 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0px 40px;
    gap: 10px;
    border-radius: 30px;

    > img {
      width: 40px;
      height: 35px;
    }
  `,
  CoinName: styled.p`
    font-weight: bold;
  `,
  ListCard: styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
  `,
  Title: styled.p`
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #ffffffbf;
    font-style: italic;
    padding: 80px 0;
  `,
  Card: styled.div`
    text-align: center;
    min-width: 300px;
  `,
  Name: styled.p`
    font-weight: bold;
    color: #ffffffbf;
  `,
  Total: styled.p`
    color: #03ffad;
    font-weight: bold;
    font-size: 34px;
    margin: 0;
  `,
  Sub: styled.p`
    font-style: italic;
    font-size: 14px;
  `,
};
