import { InputStyles } from "./styles";

export default function InputBase({
  size = "small",
  type = "normal",
  placeholder = "",
  onChange,
  ...field
}) {
  return (
    <InputStyles
      size={size}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      {...field}
    />
  );
}
