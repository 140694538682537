import axios from "axios";
import { METHOD, TIME_OUT } from "constants/config";

export function authHeader() {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") || "")
    : null;
  return user ? { Authorization: `Bearer ${user.token}` } : {};
}

export function encodeQueryString(params = {}) {
  const newObj = {};
  for (const item in params) {
    if (params[item]) {
      newObj[item] = params[item];
    }
  }
  const keys = Object.keys(newObj);
  return keys.length
    ? "?" +
        keys
          .map(
            (key) =>
              encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
          )
          .join("&")
    : "";
}

export function getHeader() {
  return {
    ...authHeader(),
    Accept: "application/json",
    "Content-Type": "application/json",
  };
}

export async function callAPI(url, method, data = {}, timeout = TIME_OUT) {
  const options = {
    method: method,
    url: method === METHOD.GET ? url + encodeQueryString(data) : url,
    data: data,
    withCredentials: true,
    headers: getHeader(),
  };
  return handleFetch(options, timeout);
}

export async function handleFetch(options, timeout) {
  try {
    const res = await fetchWithTimeout(options, timeout);

    if (res.status !== 200) {
      const errors = res || res.statusText;
      //  AUTHORZATION
      if (res.status === 401) {
        return { isOk: false, data: {}, isInvalid: false };
      }
      // SERVER ERROR
      if (res.status >= 500) {
        return { isOk: false, data: {}, isInvalid: false, serverError: true };
      }
      // NOT FOUND
      if (res.status === 404) {
      }

      return { isOk: false, data: errors, isInvalid: false };
    }
    // SUCCESS
    return { isOk: true, data: res?.data, isInvalid: true };
  } catch (error) {
    return { isOk: false, data: {}, isInvalid: false };
  }
}

async function fetchWithTimeout(options, timeout) {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await axios(
    {
      ...options,
    },
    getHeader()
  );
  clearTimeout(id);
  return response;
}
