import { EcoSystemStyles, Wrapper } from "./styles";
import Title from "components/Molecules/Title";
import { Swiper, SwiperSlide } from "swiper/react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { listEcoSystem } from "./dummy";

export default function EcoSystem() {
  return (
    <Wrapper>
      <div className="container">
        <EcoSystemStyles.Main>
          <Title
            title={"Eco System"}
            subTitle={
              "The ecosystem is designed to operate synchronously and integrate well with each other."
            }
          />
          <br /> <br />
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            centeredSlides={true}
            loop={true}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            data-aos="zoom-in"
          >
            {listEcoSystem.map((item, idx) => (
              <SwiperSlide key={idx}>
                <EcoSystemStyles.Card data-aos="zoom-in">
                  <EcoSystemStyles.Image src={item.image} />
                  <EcoSystemStyles.Title>
                    {item.title}
                    <ChevronRightIcon
                      fontSize={"large"}
                      sx={{ color: "#FF00E5" }}
                    />
                  </EcoSystemStyles.Title>
                </EcoSystemStyles.Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </EcoSystemStyles.Main>
      </div>
    </Wrapper>
  );
}
