import { styled } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: fit-content;
`;

export const FAQStyles = {
  Main: styled.div`
    padding: 100px 0;
  `,
  ListFAQ: styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    padding-top: 80px;
    text-align: left;
    > div {
      height: ${(props) => (props.Mheight ? "auto" : "fit-content")};
      color: white;
      padding: 5px;
      margin: 0 !important;
    }
    @media (max-width: 991px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
    }
  `,
};
