import { callAPI } from "api";
import { METHOD } from "constants/config";
import { endpointAPI } from "constants/endPointAPI";
import { MESSAGE } from "constants/message";
import {
  setUserInfo,
  signInFail,
  signInStart,
  signInSuccess,
} from "redux/slice/userSlice";
import { toastMessage } from "utils/toastMessage";

// LOGIN
export async function handleSignin(dispatch, data) {
  dispatch(signInStart());
  const res = await callAPI(endpointAPI.auth.signin, METHOD.POST, data);
  if (res.isOk) {
    localStorage.setItem("user", JSON.stringify(res?.data?.data));
    dispatch(setUserInfo(res?.data?.data));
    return dispatch(signInSuccess(res?.data?.data));
  } else {
    //  ERROR
    toastMessage("Operation failed, please try again!", MESSAGE.ERROR);
    localStorage.removeItem("user");
    dispatch(setUserInfo(null));
  }
  return dispatch(signInFail(res.data.errors));
}
