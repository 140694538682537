import { FAQStyles, Wrapper } from "./styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { dummyFAQ } from "./dummy";
import { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Title from "components/Molecules/Title";

export default function FAQ() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Wrapper data-aos="fade-up">
      <div className="container">
        <FAQStyles.Main>
          <Title
            title={"FAQ"}
            subTitle={
              "Learn about how to get the most from our service's special features."
            }
          />
          <FAQStyles.ListFAQ>
            {dummyFAQ?.map((e, i) => (
              <Accordion
                expanded={expanded === i}
                onChange={handleChange(i)}
                style={{
                  borderRadius: "32px",
                  background:
                    "linear-gradient(115.36deg, rgb(45 159 104 / 40%) 33.92%, rgba(8, 2, 28, 0) 99.86%), linear-gradient(0deg, rgb(49 129 77 / 20%), rgb(49 129 89 / 20%))",
                }}
                Mheight={expanded === i}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === i ? (
                      <ArrowDropDownIcon style={{ color: "white" }} />
                    ) : (
                      <ArrowRightIcon style={{ color: "white" }} />
                    )
                  }
                  aria-controls={`panel${i}bh-content`}
                  id={`panel${i}bh-header`}
                >
                  <Typography
                    sx={{ width: "50%", flexShrink: 0, fontStyle: "italic" }}
                  >
                    {e.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    height: `${expanded === i
                      } ? height:"auto !important" : height:'0 !important'`,
                  }}
                >
                  <Typography sx={{ color: "#ffffffa8", fontStyle: "italic" }}>
                    {e?.des}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </FAQStyles.ListFAQ>
        </FAQStyles.Main>
      </div>
    </Wrapper>
  );
}
