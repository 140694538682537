import iconTele from "assets/svg/icon-tele.svg";
import iconTw from "assets/svg/icon-tw.svg";
import iconYoutube from "assets/svg/icon-youtube.svg";
import iconFB from "assets/svg/icon-fb.svg";
import iconIns from "assets/svg/icon-ins.svg";

export const socials = [
  {
    icon: iconTele,
    href: "https://t.me/tobechainglobal",
  },

  {
    icon: iconTw,
    href: "https://twitter.com/i/flow/login?redirect_after_login=%2Ftobechain",
  },
  {
    icon: iconYoutube,
    href: "https://www.youtube.com",
  },
  {
    icon: iconFB,
    href: "https://www.facebook.com/tobechain",
  },
  {
    icon: iconIns,
    href: "",
  },
];
