import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import dayjs from "dayjs";
import { ChartStyles } from "./styles";
import imgTBC from "assets/img/icon-tbc.png";
import { useDispatch, useSelector } from "react-redux";
import { masterSelector } from "redux/slice/masterSlice";
import { useEffect, useState } from "react";
import { getDataChart } from "services/master";
import { formatNumber } from "utils/common";

const Chart = () => {
  const dispatch = useDispatch();

  const { coinInfo, dataChart } = useSelector(masterSelector);
  const [data, setData] = useState([]);
  const [typeChart, setTypeChart] = useState("today");

  const [percent, setPercent] = useState(0);

  useEffect(() => {
    getDataChart(dispatch, { pair: "TBC_USDT", type: "today" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataChart && coinInfo) {
      let data = [];
      if (dataChart?.length === 0) {
        data.push({ date: dayjs().startOf('day'), price: coinInfo.price })
      }
      else data = dataChart
      setData([...data, { date: dayjs(), price: coinInfo.price }]);
      const change = coinInfo?.price - data[0]?.price;
      const percentPair = (change / data[0]?.price) * 100;
      setPercent((percentPair));

    }
  }, [dataChart, coinInfo]);

  const handleChangeType = (type) => {
    setTypeChart(type);
    getDataChart(dispatch, { pair: "TBC_USDT", type });
  };

  const markLine = {
    data: [
      {
        yAxis: coinInfo?.price?.toString() || 0,
        lineStyle: {
          color: percent >= 0 ? "#31D0AA" : "#ff0365f5",
        },
        label: {
          show: true,
          position: "end",
          formatter: `$${coinInfo?.price || 0}`,
          textStyle: {
            color: "white",
            fontSize: 12,
            fontWeight: "bold",
            backgroundColor: percent >= 0 ? "#31D0AA" : "#ff0365f5",
            padding: [4, 8],
            borderRadius: 4,
          },
        },
      },
    ],
  };

  const option = {
    tooltip: {
      trigger: "axis",
      position: function (pt) {
        return [pt[0], "10%"];
      },
      formatter: function (params) {
        const time = params[0].name;
        const value = params[0].value;
        return `${time}<br /> <b>Price:<b> $${formatNumber(value)}`;
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: data.map((item) =>
        dayjs(item.date).format("MMM DD, YYYY, hh:mm A")
      ),
    },
    yAxis: {
      type: "value",
      boundaryGap: data.map((item) => item.price),
      splitLine: {
        show: false,
      },
    },
    series: [
      {
        name: "TBC/USDT",
        type: "line",
        symbol: "none",
        sampling: "lttb",
        markLine: markLine,
        itemStyle: {
          color: percent >= 0 ? "#31D0AA" : "rgb(255, 70, 131)",
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: percent >= 0 ? "#31d0aa" : "#ff0365f5",
            },
            {
              offset: 1,
              color: percent >= 0 ? "#31d0aa4d" : "#ff0365f5",
            },
          ]),
        },
        data: data.map((item) => item.price),
      },
    ],
  };

  return (
    <ChartStyles.Main>
      <ChartStyles.Pair>
        <ChartStyles.Icon src={imgTBC} alt="TBC" />
        <ChartStyles.PairName>TBC/USDT</ChartStyles.PairName>
      </ChartStyles.Pair>
      <ChartStyles.Info>
        <div>
          <ChartStyles.Price>
            <b>${coinInfo?.price} </b>
            <span style={{ color: percent >= 0 ? "#31D0AA" : "#ff0365f5" }}>
              ({percent >= 0 && "+"}
              {formatNumber(percent)}%)
            </span>
          </ChartStyles.Price>
          <ChartStyles.Date>
            {dayjs().format("MMM DD, YYYY, hh:mm A")}
          </ChartStyles.Date>
        </div>

        <ChartStyles.ListOption>
          <ChartStyles.Option
            isActive={typeChart === "today"}
            onClick={() => handleChangeType("today")}
          >
            24H
          </ChartStyles.Option>
          <ChartStyles.Option
            isActive={typeChart === "week"}
            onClick={() => handleChangeType("week")}
          >
            1W
          </ChartStyles.Option>
          <ChartStyles.Option
            isActive={typeChart === "month"}
            onClick={() => handleChangeType("month")}
          >
            1M
          </ChartStyles.Option>
          <ChartStyles.Option
            isActive={typeChart === "year"}
            onClick={() => handleChangeType("year")}
          >
            1Y
          </ChartStyles.Option>
        </ChartStyles.ListOption>
      </ChartStyles.Info>

      <ReactECharts option={option} />
    </ChartStyles.Main>
  );
};

export default Chart;
