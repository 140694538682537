import { styled } from "styled-components";

const randomPosition = () =>
  `${Math.random() * -50}vw, ${Math.random() * -50}vh`;

export const EffectStyles = styled.img`
  position: fixed;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
  width: 100%;
  animation: moveEffect 20s infinite;
  opacity: 0.6;
  @keyframes moveEffect {
    0%,
    100% {
      transform: translate(${randomPosition()}) rotate(0deg);
      opacity: 0.8;
    }
    50% {
      transform: translate(${randomPosition()}) rotate(360deg);
      opacity: 0.3;
    }
  }
`;
