import { styled } from "styled-components";

export const SwapStyles = {
  Main: styled.div`
    margin: 100px 0 100px 0;
    display: flex;
    width: 100%;
    gap: 10px;
    @media (max-width: 1240px) {
      flex-direction: column;
    }
  `,
};
