import { styled } from "styled-components";

export const Wrapper = styled.div``;

export const TitleStyles = {
  MainTitle: styled.h1`
    font-weight: bold;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
    color: #03ffad;
    margin: 0;
  `,
  SubTitle: styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    max-width: 650px;
    margin: auto;
  `,
};
